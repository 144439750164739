// react
import React, { useState, useEffect, useRef } from 'react'
// react bootstrap components
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
// external modules
import * as hdf5 from 'jsfive/index.js'
import { v4 as uuidv4 } from 'uuid'
// ui 
import 'bootstrap/dist/css/bootstrap.css'
import { FcOk, FcCancel } from 'react-icons/fc'
// internal components
import DepletionStationOptions from 'components/options/station/depletion'
import DepletionUnitOptions from 'components/options/unit/depletion'
import Layout from 'components/layout'
import DepletionPreviewGraph from 'components/graph/depletion_preview_graph'
import { GlobalDispatchContext } from 'context/global_context_provider'
import FeatureValidationTable from 'components/featureValidationTable'
import fileInfo from 'static/file_info.json'
// internal functions
import upload from 'tools/aws/upload'
import { reviewDepletionHdf, buildCycleId } from 'tools/fileReview'

const validForUpload = (review) => {
  let result = true
  console.log(review)
  Object.keys(review).forEach(k => {
    const missingPoints = review[k].length
    if (missingPoints === review.points) {
      result = false
    }
  })
  if (review.points === 0){
    result = false
  }
  return result
}

const PredictionsAddPage = (props) => {
  const [file, setFile] = useState('')
  const [batchSize, setBatchSize] = useState('')
  const [station, setStation] = useState('')
  const [unit, setUnit] = useState('')
  const [cycle, setCycle] = useState('')
  const [scenario, setScenario] = useState('')
  const [name, setName] = useState('')
  const [notes, setNotes] = useState('')
  const [review, setReview] = useState(false)
  const [hdf, setHdf] = useState(null)
  const [jobId, setJobId] = useState(uuidv4())
  const [block, setBlock] = useState(false)
  const dispatch = React.useContext(GlobalDispatchContext)
  const fileRef = useRef()

  const reset = () => {
    fileRef.current.value = ''
    setName('')
    setFile('')
    setBatchSize('')
    setStation('')
    setUnit('')
    setCycle('')
    setScenario('')
    setNotes('')
    setReview(false)
    setHdf(null)
  }

  const onLoadEnd = e => {
    const barr = e.target.result
    const hdf = new hdf5.File(barr, file.name)
    const cycleId = hdf.keys[0]
    const expectedId = buildCycleId(station, unit, cycle)
    console.log(cycleId, expectedId)
    if (cycleId !== expectedId) {
      const errorText = (
        'Failed validation: Uploaded .hdf5 root key (' + cycleId
        + ') does not match the expected cycle ID of ' + expectedId 
        + '. Ensure the station, unit, & cycle are correct in'
        + ' the upload form'
      )
      dispatch({ type: 'removeAlert', id: jobId })
      dispatch({
        type: 'addAlert',
        toAdd: {
          type: 'warningText',
          text: errorText
        }
      })
    } else {
      const data = hdf.get(cycleId)
      setHdf(data)
    }
  }
  const loadFile = () => {
    const reader = new FileReader()
    if (file) {
      reader.onloadend = onLoadEnd
      reader.readAsArrayBuffer(file)
      dispatch({
        type: 'addAlert',
        toAdd: {
          type: 'loading',
          id: jobId,
          text: 'Reviewing file'
        }
      })
    } else {
      dispatch({
        type: 'addAlert',
        toAdd: {
          type: 'warningText',
          id: jobId,
          text: 'Please select a file to upload'
        }
      })
    }
  }

  useEffect(() => {
    if (hdf) {
      const result = reviewDepletionHdf(hdf)
      dispatch({ type: 'removeAlert', id: jobId })
      if (!validForUpload(result)) {
        setBlock(true)
      }
      setReview(result)
    }
  }, [hdf])
  useEffect(() => {
    if (station && scenario && unit && !cycle) {
      const operatingCycle = (
        props.appInfo.stations[station].units[unit].operatingCycle
      )
      setCycle(
        (scenario === 'operating')
          ? operatingCycle
          : operatingCycle + 1
      )
    }
  }, [station, unit, scenario])
  useEffect(() => {
    setUnit(
      (!!station)
        ? Object.entries(props.appInfo.stations[station].units)[0][0]
        : ''
    )
  }, [station, dispatch, jobId])
  const validStations = Object.entries(props.appInfo.stations).filter(x => (
    Object.entries(x[1].units).some(x => x[1].hasOwnProperty('model'))
  ))
  const setCycleWithDefault = e => {
    setCycle((cycle) ? e.target.value : '30')
  }
  const givenNameProfile = (
    (props.data)
      ? props.data.profile.givenName
      : ''
  )
  const familyNameProfile = (
    (props.data)
      ? props.data.profile.familyName
      : ''
  )
  const uploadFile = async (event) => {
    if (name === '') {
      dispatch({
        type: 'addAlert',
        toAdd: {
          type: 'warningText',
          text: 'Depletion name is required'
        }
      })
      return
    }
    const batch = (batchSize) ? '-1' : batchSize
    console.log(batch)
    const params = {
      station: station,
      unit: unit,
      cycle: cycle,
      batchSize: (batchSize === '') ? '-1' : batchSize,
      scenario: scenario,
      name: name,
      owner: familyNameProfile + ', ' + givenNameProfile,
      notes: notes
    }
    const copy = file
    console.log(params)
    reset()
    await upload('mco', 'prediction', copy, params, dispatch)
  }
  const emailProfile = (
    (props.data)
      ? props.data.profile.email
      : ''
  )

  const usernameProfile = (
    (props.data)
      ? props.data.profile.email.slice(0, props.data.profile.email.indexOf('@'))
      : ''
  )

  const reviewTableRow = (feature) => (
    <tr>
      <td>{feature}</td>
      <td>
      <Container className='p-0'>
        <Row xs='auto' className='justify-content-center'>
          <Col xs='auto' className='p-0'>
            {
              (review[feature].length > 0)
                ? <FcCancel />
                : <FcOk />
            }
          </Col>
        </Row>
      </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              {review.points}
            </Col>
          </Row>
        </Container>
      </td>
      <td>
        <Container className='p-0'>
          <Row xs='auto' className='justify-content-center'>
            <Col xs='auto' className='p-0'>
              {review.points - review[feature].length}
            </Col>
          </Row>
        </Container>
      </td>
      <td>
        {
          (review[feature].length > 0)
            ? (<b>Missing Points: </b>)
            : null
        }        
        {
          (review[feature].length > 0)
            ? review[feature].join(', ')
            : null
        }
      </td>
    </tr>
  )
  const features = Object.keys(fileInfo.mco.depletion.features)
  const reviewTable = (
    (review)
      ? (
          <>
            <Row xs='auto' className='justify-content-center'>
              <Table
                striped
                bordered
                hover
                style={{
                  boxShadow: (
                    '0 10px 20px rgba(0,0,0,0.19),' +
                    ' 0 6px 6px rgba(0,0,0,0.23)'
                  ) 
                }}
              >
                <thead>
                  <tr>
                    <th>Feature</th>
                    <th>
                      <Container className='justify-content-center p-0'>
                        <Row xs='auto' className='justify-content-center'>
                          <Col xs='auto'>
                            Validation
                          </Col>
                        </Row>
                      </Container>
                    </th>
                    <th>Expected Points</th>
                    <th>Points in File</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  {features.map(x => reviewTableRow(x))}
                </tbody>
              </Table>
            </Row>
          </>
        )
      : null
  )
  return (
    <>
      <Container>
        <Row xs='auto' className='justify-content-center'>
          <Col xs='auto'>
            <Row className='mb-4'>
              <h1
                className='featurette-heading text-center'
                style={{ fontWeight: '100' }}
              >
                Depletion File Upload
              </h1>
            </Row>
            <Card style={{ border: 'none' }}>
              <Card.Body>
                <Form>
                  <Row className='mb-3'>
                    <Col xs='4'>
                      <Form.Group>
                        <Form.Label> Station </Form.Label>
                        <Form.Select
                          size='sm'
                          value={station}
                          onChange={e => setStation(e.target.value)}
                        >
                          <option key='sel-0' value='' />
                          <DepletionStationOptions />
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs='auto'>
                      <Form.Group>
                        <Form.Label> Unit </Form.Label>
                        <Form.Select
                          size='sm'
                          value={unit}
                          onChange={e => setUnit(e.target.value)}
                        >
                          <DepletionUnitOptions station={station} />
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs='auto'>
                      <Form.Group>
                        <Form.Label>Scenario</Form.Label>
                        <Form.Select
                          size='sm'
                          value={scenario}
                          onChange={e => setScenario(e.target.value)}
                        >
                          <option value=''></option>
                          <option value='design'>Design</option>
                          <option value='operating'>Operating</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xs='2'>
                      <Form.Group>
                        <Form.Label> Cycle </Form.Label>
                        <Form.Control
                          size='sm'
                          type='number'
                          min='0'
                          max='1000'
                          value={cycle}
                          onChange={setCycleWithDefault}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3 justify-content-center'>
                    <Col xs='8'>
                      <Form.Group>
                        <Form.Label> File </Form.Label>
                        <Form.Control
                          type='file'
                          size='sm'
                          ref={fileRef}
                          onChange={e => {
                            setFile(e.target.files[0])
                          }}
                        />
                        <Form.Text className='text-muted' style={{ fontWeight: '100' }}>
                          Please select a depletion file to upload
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs='4'>
                      <Form.Group>
                        <Form.Label> Batch Size </Form.Label>
                        <Form.Control
                          type='number'
                          min='0'
                          max='1000'
                          size='sm'
                          value={batchSize}
                          onChange={e => setBatchSize(e.target.value)}
                        />
                        <Form.Text className='text-muted' style={{ fontWeight: '100' }}>
                          Optional
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col xs='6'>
                      <Form.Group>
                        <Form.Label>Owner</Form.Label>
                        <Form.Control
                          disabled
                          type='text'
                          size='sm'
                          value={familyNameProfile + ', ' + givenNameProfile}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs='6'>
                      <Form.Group>
                        <Form.Label> User Email </Form.Label>
                        <Form.Control
                          disabled
                          type='email'
                          size='sm'
                          value={emailProfile}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col xs='6'>
                      <Form.Group>
                        <Form.Label> Depletion Name </Form.Label>
                        <Form.Control
                          type='text'
                          size='sm'
                          value={name}
                          onChange={e => {
                            if (/^[\w\s\-_ %.+]*$/.test(e.target.value)) {
                              setName(e.target.value)
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs='6'>
                      <Form.Group>
                        <Form.Label> User Name </Form.Label>
                        <Form.Control
                          disabled
                          type='text'
                          size='sm'
                          value={usernameProfile}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col xs='12'>
                      <Form.Group>
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          as='textarea'
                          size='sm'
                          value={notes}
                          onChange={e => setNotes(
                              e.target.value.replace(/[^\w\s.,]/gi, "")
                          )}
                        />
                        <Form.Text className='text-muted' style={{ fontWeight: '100' }}>
                          Optional
                        </Form.Text>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mb-3 justify-content-center'>
                    <Button
                      variant='outline-primary'
                      style={{ width: '25%', borderRadius: '25px' }}
                      onClick={loadFile}
                    >
                      Review
                    </Button>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {
          (review)
            ? <FeatureValidationTable
                review={review}
                service='mco'
                type='depletion'
              />
            : null
        }
      </Container>
      <Container fluid>
        <Row className='mb-3 justify-content-center'>
          <Col xs='auto justify-content-center'>
            {
              (hdf)
                ? <DepletionPreviewGraph hdf={hdf} />
                : null
            }
          </Col>
        </Row>
        <Row className='mb-3 justify-content-center'>
          {
            (review && !block)
              ? (
                  <Button
                    variant='outline-primary'
                    style={{ width: '25%', borderRadius: '25px' }}
                    onClick={uploadFile}
                  >
                    Submit
                  </Button>
                )
              : null
          }
        </Row>
      </Container>
    </>
  )
}

PredictionsAddPage.Layout = Layout

export default PredictionsAddPage
